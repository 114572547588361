/* ///////////////////////////// INDEX /////////////////////////////// */

main section {
    padding: var(--section_padd) 0;
}

#artisan, #fabricant {
    display: block;
    color: var(--color1);
    font-weight: 600;
    text-shadow: 1px 1px 2px rgb(255,255,255,0.2);
}

#artisan {
    font-size: calc(25px + 4 * (100vw - 320px) / 1080);
    padding: 20px 0;
}

#fabricant {
    font-size: calc(22px + 2 * (100vw - 320px) / 1080);
}

/* *** BIENVENUE *** */

#bienvenue {
    background-image: url("../../public/img/general/background-marbre.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

#bienvenue p {
    text-align: center;
}

/* *** MARBRERIE *** */


#marbrerie-index .ct > div{
    display: flex;
    margin: var(--section_padd) 0;
    align-items: center;
}

#marbrerie-index .ct > div > * {
    width: 50%;
}

#marbrerie-index .ct > div > picture img {
    min-height: 62vh;
    object-fit: cover;
    border-radius: 10px;
}

#marbrerie-index .ct > div:first-child > div {
    margin-right: 6%;
}

#marbrerie-index .ct > div:nth-child(2) > div {
    margin-left: 6%;
}

#marbrerie-index .ct > div:first-child > div .bn {
    float: right;
}


/* *** REALISATIONS *** */



#rea-index {
    background-color: #fff;
}

#rea-index #flex-reaindex {
    margin-top: calc(25px + 10 * (100vw - 320px) / 1080);
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

#rea-index #flex-reaindex > div a{
    border-radius: 10px;
    overflow: hidden;
    transition: all 5s;
    transition-duration: 5s;
}

#rea-index #flex-reaindex > div a img {
    transition: all 5s;
    transition-duration: 5s;
    height: 100%;
    object-fit: cover;
}

#rea-index #flex-reaindex > div:hover a img {
    transform: scale(1.1);
}

#rea-index #flex-reaindex > div picture {
    overflow: hidden;
    border-radius: 10px;
    height: calc(140px + 100 * (100vw - 320px) / 1080);
}

#rea-index #flex-reaindex .des-reaindex svg {
    width: 30px;
    fill: var(--colorw);
    stroke: var(--colorw);
    stroke-width: 2px;
}

#rea-index #flex-reaindex .des-reaindex span {
    font-size: calc(16px + 2 * (100vw - 320px) / 1080);
    font-weight: 500;
}

/* *** ATELIERS *** */

#atelier-index {
    background-color: var(--color1);
    display: flex;
    align-items: center;
}

#atelier-index > picture {
    width: 50%;
    height: 100%;
}

#atelier-index > picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#atelier-index > div {
    padding: 2% 5%;
    width: 50%;
}

#atelier-index h3, #atelier-index a {
    color: var(--colorw);
}

#atelier-index a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--colorw);
    padding: 20px 0;
    font-weight: 600;
    transition: all 550ms ease;
}

#atelier-index a:hover {
    background-color: #fff;
    color: var(--color1);
    padding: 20px;
}

#atelier-index svg {
    stroke: var(--color2);
    width: 4%;
    stroke-width: 0.1em;
}

/* *** MAGASINS *** */

#rea-index #flex-reaindex .des-reaindex {
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(50,51,82,0.8);
    text-align: center;
    color: #fff;
    transition: all 450ms ease;
    display: flex;

    border-radius: 10px;
}

#rea-index #flex-reaindex .des-reaindex {
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#rea-index #flex-reaindex .des-reaindex:hover {
    opacity: 1;
}


/************* max 1250 **************/ @media screen and (max-width:1250px) {

    #atelier-index > picture {
        display: none;
    }

    #atelier-index > div {
        padding: 10% 15%;
        width: 100%;
    }

     #atelier-index .title-left {
        margin: 0 0 calc(25px + 10 * (100vw - 320px) / 1080);
    }
}


/************* max 1084 **************/ @media screen and (max-width:1084px) {

    #marbrerie-index .ct > div:first-child {
        flex-direction: column-reverse;
    }

    #marbrerie-index .ct > div:nth-child(2) {
        flex-direction: column;
    }

    #marbrerie-index .ct > div > * {
        width: 100%;
    }

    .title-right + p, .title-right {
        text-align: left;
    }

    #marbrerie-index .ct > div > picture img {
        min-height: 100%;
        width: 100%;
    }

    #marbrerie-index .ct > div:first-child > div {
        margin-right: 0;
    }

    #marbrerie-index .ct > div:nth-child(2) > div {
        margin-left: 0;
    }

    #marbrerie-index .ct > div:first-child > div .bn {
        float: inherit;
    }

    #rea-index #flex-reaindex > div:nth-child(3) {
        display: none;
    }

    #rea-index #flex-reaindex > div a img {
        height: auto;
        width: 100%;
    }

}

/************* max 850 **************/ @media screen and (max-width:850px) {

    #bienvenue p {
        text-align: left;
    }

    #rea-index #flex-reaindex > div a img {
        height: 100%;
    }

    #rea-index #flex-reaindex > div picture {
        height: calc(160px + 110 * (100vw - 320px) / 1080);
    }
}

/************* max 550 **************/ @media screen and (max-width:550px) {

    #rea-index #flex-reaindex > div:nth-child(2) {
     display: none;
    }

    #rea-index #flex-reaindex > div:first-child {
        width: 100%;
    }

    #rea-index #flex-reaindex > div picture {
        height: calc(200px + 150 * (100vw - 320px) / 1080);
    }

}

/************* max 450 **************/ @media screen and (max-width:450px) {
    #atelier-index > div {
        padding: 15%;
    }
}
